import React from 'react'
import styled from 'styled-components'
import ArchitectureImage from './images/architecture'
import LogoImage from './images/logo'
import MainPhoneImage from './images/main-phone'
import ParkingImage from './images/parking'
import RobotFacingRight from '../images/robot-facing-right'
import RobotFacingLeft from '../images/robot-facing-left'

const text = {
  title: 'RTA E-Bank_',
  intro:
    'The rail & transport authority of the UAE was ready to capitalize on its massive user base by entering into the mobile wallet space, but how would they change the perception of tech savvy users who knew them only as a transportation provider...',
  sectionOne:
    'We worked closely with the board to carefully craft a campaign that would stay true to the ideals of reliability & trust the RTA had established but create the image of being a leader in financial innovation.',
  sectionTwoTitle: 'Product Design & User Experience',
  sectionTwo:
    'The product allowed a user to pay for all of the expenses they would incur on their daily commute, not just for transportation but for food, entertainment, & utilities.',
  sectionThreeTitle: 'Competitive Advantage',
  sectionThree:
    "The RTA has the user base & momentum to become the WeChat of the UAE. We researched relevant wallets to understand how to capitalize on the RTA's most pivotal advantage when launching a financial application: the trust they had already established.",
}

const Landing = () => {
  return (
    <PageContainer>
      <Background>
        <IntroContainer>
          <IntroImageContainer>
            <LogoImage />
          </IntroImageContainer>
          <IntroTextContainer>
            <IntroTitle>{text.title}</IntroTitle>
            <IntroDetail>{text.intro}</IntroDetail>
          </IntroTextContainer>
        </IntroContainer>
      </Background>
      <Background color="white">
        <TestImageRight>
          <RobotImageContainerLeft>
            <RobotFacingLeft />
          </RobotImageContainerLeft>
        </TestImageRight>
        <Section>
          <ImageContainer>
            <MainPhoneImage />
          </ImageContainer>
          <TextContainer>{text.sectionOne}</TextContainer>
        </Section>
      </Background>
      <Background color="#fefffe">
        <Section>
          <SectionTitle>{text.sectionTwoTitle}</SectionTitle>
          <TextContainer>{text.sectionTwo}</TextContainer>
          <ImageContainerFull>
            <ArchitectureImage />
          </ImageContainerFull>
        </Section>
      </Background>
      <Background color="white">
        <TestImageRight>
          <RobotImageContainerRight>
            <RobotFacingRight />
          </RobotImageContainerRight>
        </TestImageRight>
        <Section>
          <SectionTitle>{text.sectionThreeTitle}</SectionTitle>
          <TextContainer>{text.sectionThree}</TextContainer>
          <ImageContainer>
            <ParkingImage />
          </ImageContainer>
        </Section>
      </Background>
    </PageContainer>
  )
}

export default Landing

const Background = styled.div`
  background-color: ${props => props.color || 'black'};
  width: 100vw;
  position: relative;
`

const PageContainer = styled.div`
  text-align: center;
  background: white;
  color: black;
  font-family: 'Helvetica Neue';
  line-height: 1.5;
`
const ImageContainerFull = styled.div`
  width: 100%;
  margin: 0px auto;
`

const IntroContainer = styled.div`
  background: black;
  color: white;
  display: flex;
  padding: 20px 0px;
  max-width: 1200px;
  margin: 0 auto;

  @media screen and (min-width: 1200px) {
    padding: 50px 0px;
  }
`
const IntroImageContainer = styled.div`
  width: 25%;
  padding-left: 3%;
  padding: 20px 0px 20px 20px;
  margin: 0px auto;
  align-self: center;
`

const IntroTitle = styled.div`
  font-size: 28px;
  font-weight: 500;
  @media screen and (min-width: 375px) {
    font-size: calc(34px + (100 - 34) * ((100vw - 375px) / (1200 - 375)));
  }

  @media screen and (min-width: 1200px) {
    font-size: 100px;
  }
`

const IntroDetail = styled.div`
  font-size: 10px;
  padding-right: 10px;
  @media screen and (min-width: 375px) {
    font-size: calc(12px + (28 - 12) * ((100vw - 375px) / (1200 - 375)));
  }

  @media screen and (min-width: 1200px) {
    font-size: 28px;
  }
`

const IntroTextContainer = styled.div`
  width: 55%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 3%;
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${props => props.padding || '3%'};
  max-width: ${props => props.max || '1200px'};
  overflow: ${props => props.overflow || 'visible'};
  margin: 0 auto;
  position: relative;
  background: transparent;
`
const TextContainer = styled.div`
  font-size: 12px;
  color: #707070;
  width: 75%;
  margin-bottom: 20px;
  font-weight: 300;
  max-width: 800px;
  padding: 2% 0 3%;
  background-color: transparent;
  z-index: 1;

  @media screen and (min-width: 375px) {
    font-size: calc(12px + (32 - 12) * ((100vw - 375px) / (1200 - 375)));
  }

  @media screen and (min-width: 1200px) {
    font-size: 32px;
  }
`
const ImageContainer = styled.div`
  width: ${props => props.width || '85%'};
  margin: 0px auto;
`

const SectionTitle = styled.div`
  font-weight: bold;
  @media screen and (min-width: 375px) {
    font-size: calc(12px + (32 - 12) * ((100vw - 375px) / (1200 - 375)));
  }

  @media screen and (min-width: 1200px) {
    font-size: 32px;
  }
`
const RobotImageContainerRight = styled.div`
  position: absolute;
  width: 35%;
  top: 30%;
  left: -27%;
`

const RobotImageContainerLeft = styled.div`
  position: absolute;
  width: 35%;
  top: 8%;
  right: -25%;
`

const TestImageRight = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100vw;
  margin: 0px auto;
  overflow: hidden;
`
