import React from 'react'
import RTATransport from '../../components/portfolio/rta-transport/index'
import SEO from '../../components/seo'

const RTATransportPage = () => (
  <>
    <SEO title="Tabreezi Portfolio" />
    <RTATransport />
  </>
)

export default RTATransportPage
